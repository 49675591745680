.tooltip{
  background-color: transparent !important;
  background: transparent !important;
  color: white !important;
  width: auto !important;
  height: auto;
}

.heading-tooltip{
  background-color: black;
  height: auto;
  display: block;
  align-items: center;
  color: white;
  padding: 0px 10px 0px 10px;
}

.data-tooltip-main{
  background-color: transparent !important;
  background: transparent !important;
  display: flex;
  flex-direction: column;
  padding: 0px 10px 0px 10px;
}

.data-key{
  font-weight: normal;
}

.data-value{
  font-weight: bold;
}
